import React from "react"
import Logo from "components/tools/logo.js"
import { navigate } from "gatsby-link"
const Header = ({ style, title }) => {
  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          marginTop: "70px",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/front")
        }}
      >
        <Logo w={118.28} h={26.05} />
        <div
          style={{
            fontFamily: "RIDIBatang",
            fontSize: "20px",
            fontWeight: "400",
            marginLeft: "15px",
          }}
        >
          {title}
        </div>
      </div>
      <hr />
    </div>
  )
}

export default Header
